<template>
  <div class="sceneInfo-container">
    <div class="login-left">
      <div class="left-img" v-bind:style="{ backgroundImage: 'url(' + sceneBg + ')' }"></div>
      <div class="scene-mask">
        <span class="change-btn" @click="changeScene">更换场景</span>
      </div>
    </div>
    <div class="login-right">
      <router-link to="square" class="header">
        <img v-lazy="logo2" alt="logo" />
      </router-link>
      <el-form :model="form" :rules="rules" :show-message="true" :validate-on-rule-change="false" ref="form" v-loading="loading">
        <div class="form">
          <div class="input-box scene-name">
            <div class="input-flex">
              <h5>场景名称</h5>
              <el-form-item prop="title" required>
                <el-input v-model="form.title" placeholder="场景名称" class="input" maxlength="25" show-word-limit></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="input-box scene-introduction">
            <div class="input-flex">
              <h5>场景简介</h5>
              <el-form-item prop="introduction" required>
              <el-input type="textarea" v-model="form.introduction" placeholder="不多于60字" class="input" resize="none" maxlength="60" show-word-limit></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="input-box select-scene">
            <h5>场景类型</h5>
            <el-select v-model="form.tags" multiple placeholder="请选择" class="select-box" style="padding: 2px;width: 100%;">
              <el-option v-for="item in cate" :key="item.id" :label="item.title" :value="item.title">
              </el-option>
            </el-select>
          </div>
          <button class="createScene-btn" @click="createScene" v-preventReClick>
            创建场景
          </button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { getSceneDetail, getCateList, createScene } from '@/api/api'
export default {
  name: "sceneInfo",
  components: {},
  data() {
    const validateRequire = (rule, value, callback) => {
      if (!value || value === '') {
        callback(new Error(rule.message))
      } else {
        callback()
      }
    }
    return {
      logo2: require("../assets/images/logo2.png"),
      scene_listing_id: null,
      form: {
        scene_id: '',
        title: "",
        introduction: "",
        tags: []
      },
      loading: false,
      sceneBg: '',
      scene: {},
      showType: false,
      // hasScene: false, // 默认没有场景
      showSceneIntroduction: true,
      ShowSceneInName: true,
      paginate: 20,
      cate: [],
      cate_id: null,
      rules: {
        title: [
          { validator: validateRequire, message: '请输入场景名称', trigger: 'blur' }
        ],
        introduction: [
          { validator: validateRequire, message: '场景简介不可为空', trigger: 'blur' }
        ],
      }
    }
  },
  methods: {
    changCate(e) {
      this.cate_id = e
    },
    getCateList() {
      const params = {
        paginate: this.paginate
      }
      getCateList(params).then(res => {
        if (res.code == 200) {
          this.cate = res.data.data
        }

      })
    },
    getSceneDetail() {
      const params = {
        scene_listing_id: this.scene_listing_id
      }
      getSceneDetail(params).then(res => {
        if (res.code == 200) {
          this.scene = res.data
          this.form.scene_id = res.data.scene_id
          this.sceneBg = res.data.pic
        }
      })
    },
    // 创建场景
    createScene() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true
          createScene(this.form).then(res => {
            this.loading = false
            if (res.code == 200) {
              this.$router.push({ path: 'square?type=2' })
            }
          }).catch(_ => {
            this.loading = false
          })
        }
      })
    },
    // 更换场景
    changeScene() {
      this.$router.back(-1)
    },
    selectType(item) {
      this.showType = false
      this.form.tags.push(item.title)
    },
    showTypeFn() {
      this.showType = !this.showType
    },
    showChange() {
      this.hasScene = true
    },
    hiddenChange() {
      this.hasScene = true
    }
  },
  mounted() {
    this.$nextTick().then(res => {
      const query = this.$route.query
      this.scene_listing_id = query.id ? query.id : null
      if (this.scene_listing_id) {
        this.getSceneDetail()
      }
      this.getCateList()
    })
  }
}

</script>
<style scoped lang="scss">
@import '../assets/css/sceneInfo.scss';

.left-img {

  background-size: cover;
  width: 100%;
  height: 100%;
}

::v-deep .el-input__inner {
  border: none;
}

::v-deep .el-select .el-input .el-select__caret {
  color: #000;
  font-size: 28px;
  font-weight: bold;
}

::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #000;
  background-color: #FFF;
}

::v-deep .input .el-input__inner {
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: inset 0.25rem 0.25rem 0.4375rem -0.1875rem rgb(27 27 27 / 25%);
  border-radius: 0.375rem;
}

::v-deep .input .el-textarea__inner {
  border: 0.0625rem solid rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  box-shadow: inset 0.25rem 0.25rem 0.4375rem -0.1875rem rgb(27 27 27 / 25%);
  border-radius: 0.375rem;
}

</style>
